@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@layer base {
  * {
    font-family: "Mulish", sans-serif !important;
  }
}
/* html {
  scroll-behavior: smooth;
} */

a:hover {
  @apply text-primary;
}
.introBg {
  background: linear-gradient(
      300.6deg,
      rgba(0, 76, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 37.94%
    ),
    linear-gradient(
      121.03deg,
      rgba(44, 238, 227, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 22.03%
    ),
    #0d0c16;
}
.aboutusBg {
  background: linear-gradient(
      249.77deg,
      rgba(0, 76, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 37.05%
    ),
    linear-gradient(
      67.49deg,
      rgba(44, 238, 227, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 20.46%
    ),
    #0d0c16;
}
.aboutusItem {
  background: linear-gradient(
      21.83deg,
      rgba(255, 255, 255, 0.03) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 255, 255, 0.05);
}
.serve {
  background: linear-gradient(
      299.3deg,
      rgba(0, 76, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 38.83%
    ),
    linear-gradient(
      114.38deg,
      rgba(44, 238, 227, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 19.07%
    ),
    #0d0c16;
}
.active_serve {
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    rgba(44, 238, 227, 0.7);
}
.parallelogram {
  border-bottom: 80px solid;
  border-bottom-color: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    rgba(44, 238, 227, 0.7);
  border-right: 25px solid transparent;
  height: 0;
}
.projects {
  background: linear-gradient(
      354.29deg,
      rgba(0, 76, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 93.26%
    ),
    linear-gradient(
      178.46deg,
      rgba(44, 238, 227, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 98.87%
    ),
    #041817;
}
.peopleSayList {
  background: linear-gradient(
      354.29deg,
      rgba(0, 76, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 93.26%
    ),
    linear-gradient(
      178.46deg,
      rgba(44, 238, 227, 0.02) 0%,
      rgba(0, 0, 0, 0.2) 98.87%
    ),
    #041817;
}
.peopleSayItem {
  background: linear-gradient(
      115.33deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 66.12%
    ),
    rgba(51, 51, 51, 0.3);
}
.text-elipse-1 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

.text-elipse-6 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  overflow: hidden;
  word-break: break-all;
}
.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  display: none !important;
}
.slick-dots li button {
  border-radius: 100%;
  height: 14px;
  width: 14px;
  background: #cccccc !important;
}
.slick-dots li.slick-active button {
  background-color: #2ceee3 !important;
}
.slick-dots {
  bottom: -65px;
}
.ourTeam {
  background: linear-gradient(
      354.29deg,
      rgba(0, 76, 255, 0.06) 0%,
      rgba(0, 0, 0, 0) 93.26%
    ),
    linear-gradient(
      178.46deg,
      rgba(44, 238, 227, 0.02) 0%,
      rgba(0, 0, 0, 0.2) 98.87%
    ),
    #041817;
}

.footer-custom {
  background: linear-gradient(
      299.3deg,
      rgba(0, 76, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 38.83%
    ),
    linear-gradient(
      114.38deg,
      rgba(44, 238, 227, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 19.07%
    ),
    #0d0c16;
}

.our_partner {
  background: linear-gradient(
      249.77deg,
      rgba(0, 76, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 37.05%
    ),
    linear-gradient(
      67.49deg,
      rgba(44, 238, 227, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 20.46%
    ),
    #0d0c16;
}

.get_in_tounch {
  background: linear-gradient(
      299.3deg,
      rgba(0, 76, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 38.83%
    ),
    linear-gradient(
      114.38deg,
      rgba(44, 238, 227, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 19.07%
    ),
    #0d0c16;
}

.ant-form-item-label label {
  @apply text-white text-[20px];
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  @apply focus:border-primary hover:border-primary bg-[#FFFFFF14] text-white rounded-[8px] border !border-[#ff4d4f] h-[44px] backdrop-blur-[13px];
}

.menu_drawer .ant-drawer-content {
  background: linear-gradient(
      249.77deg,
      rgba(0, 76, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 37.05%
    ),
    linear-gradient(
      67.49deg,
      rgba(44, 238, 227, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 20.46%
    ),
    #0d0c16;
}

.menu_drawer .ant-drawer-close {
  color: white;
}

.menu_drawer .ant-drawer-header {
  @apply border-primary;
}

.menu_drawer .ant-drawer-body {
  padding-left: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  color: white;
  -webkit-text-fill-color: white;
  box-shadow: 0 0 0px 40rem #1d1d26 inset;
  -webkit-box-shadow: 0 0 0px 40rem #1d1d26 inset;
  border-color: #8f9096;
}
.people_say_slider .slick-list {
  height: 264px;
}
